@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

.bg-poker {
    background-image: url('../../public/images/poker-background.png');
    background-size: cover;
    background-position: center;
}